import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const renderItems = edges => (
  <div className="tech-box-container">
    {edges.map((edge, index) => (
      <Link to={`/${edge.node.id}`} key={edge.node.id}>
        <div
          className={`tech-box tech-box-md-${(index + 1) %
            2} tech-box-lg-${(index + 1) % 3}`}
        >
          <p>{edge.node.name}</p>
        </div>
      </Link>
    ))}
  </div>
)

export default ({ data }) => (
  <Layout>
    <SEO />
    <div className="container full no-margin">
      <div className="container textCenter">
        <h1>
          {/* <span className="color-text">Hand</span>-picked{" "}
          <span className="color-text">list</span> of resources for */}
          <span className="color-text">Hand</span>-picked resources{" "}
          <span className="color-text">list</span> for
          <br />
          <span className="color-text">dev</span>elopers.
        </h1>
      </div>
      <div className="container no-padding">
        {renderItems(data.allListJson.edges)}
        <div className="clear"></div>
      </div>
      <div className="container">
        <p className="textCenter">More to come...</p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query IndexListQuery {
    allListJson {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
